.nav__items {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 88px;
    /* border-radius: 13px; */
    position: fixed;
    /* top: 20px; */
    left: 50%;
    transform: translate(-50%);
    z-index: 1;
}

.nav__logo {
    color: #222;
    font-size: 2rem;
    cursor: pointer;
}

.nav__menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
}

.nav__links {
    text-decoration: none;
    color: #222;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}

.nav__links i {
    padding-right: 10px;
}

.nav__links:hover {
    background-color: #04bade;
    color: white;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}


.nav__links__mobile {
    display: none;
}

.menu__icons {
    display: none;
}

button {
    padding: 0.5rem 1rem;
    white-space: nowrap;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

button:hover {
    background-color: black;
    color: white; 
}


@media screen and (max-width: 850px) {

.nav__items {
    z-index: 1;
  }

.nav__menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: -100%;
    opacity: 0;
    align-items: stretch;
    padding: 80px 0 30px 0;
    margin: 0;
    transition: 0.3s ease-in-out;
  }

.nav__menu.active {
    left: 0;
    opacity: 1;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }

.nav__links {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 2rem 0;
  }

.nav__links:hover {
    background-color: #ff8b3d;
    transition: none;
   }

   /* button {
    display: none;
   }

.nav__links__mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: auto;
    border-radius: 4px;
    width: 80%;
    background-color: blue;
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 2px;
    } */

.menu__icons {
    display: block;
    cursor: pointer;
   }

.menu__icons i {
    font-size: 1.2rem;
    color: #222;
   }

} 