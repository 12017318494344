.news-ticker-container {
    background-color: #333;
    overflow: hidden;
  }
  
  .news-ticker {
    white-space: nowrap;
    animation: tickerAnimation 15s linear infinite;
    color: #fff;
    padding: 10px;
  }
  
  .news-ticker a {
    color: #00f;
    text-decoration: none;
  }
  
  @keyframes tickerAnimation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }