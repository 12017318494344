.head {
    margin-top: 9rem;
    margin-bottom: -5%;
}

.gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    padding: 0 12px;
    margin-top: 0.75rem;
}

.gallery .pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

.gallery .pics img {
    transition: transform 0.3s ease-in-out;
}

.gallery .pics:hover {
    filter: opacity(0.8);
}


@media screen and  (max-width: 991px) {
    .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media screen and  (max-width: 480px) {
    .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-count: 100%;
    padding: 12px;
  }
}


/* model */

.model {
    width: 100%;
    height: 80%;
    position: fixed;
    top: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 3;
}

.model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model img {
    max-width: auto;
    height: auto;
    /* max-width: 100%; */
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.model.open .close-icon {
    position: fixed;
    top: 10px;
    right: 10px;
    height: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    cursor: pointer;
}


