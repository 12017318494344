.contactform {
    width: 100%;
    padding: 40px;
    /* display: flex;
    flex-direction: column; */

    /* Added padding-top to change view issue  */
    padding-top: 0;
    /* changed height from 120vh to 70vh to  make it look better on mobile. */
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    background: linear-gradient(90deg  #0e3959 0%, #0e3959 30%, #03a9f5 30%, #03a9f5 100%,);
    /* background-color: #2c2f48; */
}

.contact__container {
    max-width: 960px;
    min-height: 490px;
    margin: auto;
    /* padding: 40px; */
    width: 100%;
    height: auto;
    gap: 1.5rem;
    background: white;
    box-shadow: 0 0 1rem hsla(0 0 0 / 16%);
    color: #2a2a2a2a;
    border-radius: 0.5rem;
    overflow: hidden;
}

.form__container {
    padding: 20px;
}

.form__container h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: black;
}

.contact__form {
    display: grid;
    row-gap: 1rem;
}

.contact__form input, textarea {
    width: 100%;
    border: 1px solid;
    outline: none;
    background: white;
    padding: 10px;
    font-size: 0.9rem;
    color: black;
    border-radius: 0.4rem;
}

.contact__form textarea {
    resize: none;
    height: 200px;
}

.contact_form .send_button {
    border: solid 3px black;
    outline: none;
    background: whitesmoke;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
}

.contact_form .send_button:hover {
    background-color: #03a9f5;
    color: white;
}

.map h3 {
    color: black;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.map p {
    color: black;
    font-size: 1rem;
}

.map p i {
    color: blue;
    gap: 1rem;
}


.map iframe {
    width: 100%;
    height: 100%;
    height: 400px;
}

@media (max-width: 850px) {

    .contactform {
        height: 80vh;
    }

    .contact__container {
        grid-template-columns: 1fr;
        justify-content: center;
    }

} 



    .send_button{
        width: 200px;
        margin:  0 auto;
    }
