.programbox {
    margin: 2rem 2rem;
    color: black;
    margin-bottom: 1rem;
    margin-top: 5rem;
    margin-bottom: 8rem;
}

.programbox h1 {
    margin-bottom: 2rem;
}



.programcard {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    gap: 1.5rem;
}

.p__card {
    width: 100%;
    text-align: start;
    box-shadow: 0 5px 2px rgba(0, 0, 0, 0.05);
    border: solid black;
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem .8rem;
}

.p__image {
    height: 200px;
    overflow: hidden;
    border-radius: 7px;
}

.p__image img {
    width: 100%;
    height: 100%;
    transition: 0.3 ease-in-out;
    border-radius: 7px;
}

/* .p__image img:hover {
    transform: scale(1.3);
} */

.p__card h4 {
    font-size: 1.4rem;
    padding: .9rem 0 .2rem 0;
}





@media screen and (max-width: 850px) {
    .program {
        margin: 4rem 2rem;
    }

    .programbox p {
        text-align: left;
        max-width: 95%;
    }
    .programcard {
        grid-template-columns: 1fr;
    }

    .p__card {
        width: 100%;
        margin-bottom: 1.5rem;
    }
}


@media screen and (min-width: 851px) and (max-width: 1200px) {
    .programcard {
        grid-template-columns: repeat(2, 1fr); 
    }
}