.arrest__container {
  margin: 4rem 2rem;
  /* border: 1px solid black; */
  padding: 10px;

}

.arrest__container h3, h5, h4 {
  text-align: left;
}

.intro {
  color: red;
}

.into {
  color: lightblue;
}

.arrest__container p {
  margin-bottom: 1rem;
  text-align: left;
}

.hi {
  background: yellow;
}