.policy__container {
    margin: 2rem 2rem;
    padding: 20px;
    justify-content: center;
    text-align: left;
    /* border: 1px solid black; */
}

.policy__container p {
    margin-bottom: 20px;
}
