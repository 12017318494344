.container-1 {
    margin: 4rem 2rem; /* Adjusted margin for smaller screens */
    padding: 20px;
}

.box {
    width: 100%;
    display: flex;
    flex-direction: column; /* Change to column for small and medium screens */
    text-align: start;
    box-shadow: 0 5px 2px rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem 0.8rem;
    gap: 2.5rem;
    
}

.volunteer__box {
    width: 100%; /* Full width for small and medium screens */
}

.volunteer__box a{
    color: #ff8b3d;
}

.volunteer__img img {
    width: 100%; /* Make the image fill the container */
    border-radius: 20px;
    max-width: 100%;
}

/* Media query for small and medium screens */
@media (min-width: 768px) {
    .box {
        flex-direction: row; /* Change back to row for larger screens */
    }

    .volunteer__box {
        width: 100%;
    }
}

@media (max-width: 1000px) {
    .container-1 {
        margin: 4rem 0; /* Adjusted margin to center the content */
        padding: 0 20px; /* Adjusted padding */
    }

    .box {
        flex-direction: column; /* Keep the column layout for smaller screens */
    }

    .volunteer__img img {
        width: 100%; /* Make the image fill the container */
        border-radius: 20px;
        max-width: 100%;
    }
}
