.blog__container {
    margin-right: 2rem 4rem;
    padding: 20px;
}

.box__container {
    display: flex;
    gap: 3rem;
    margin-top: 10%;
}

.box__one {
    flex: 1;
    border: 3px solid black;
    border-radius: 10px;
    padding: 20px;
    max-width: 65%;
    text-align: left;
    /* background-color: whitesmoke; */
}

.box__one h2 {
    text-align: left;
}

.box__one img {
    width: 100%;
    height: 300px;
    margin-bottom: 3%;
    
}

.box__two {
    max-width: 25%;
    border: 3px solid black;
    height: 40vh;
    /* background: whitesmoke; */
    padding: 7px;
    border-radius: 10px;
}

.box__two p {
    margin-top: 7%;
    font-weight: 500;
    font-size: 14px;
}

.box__two button {
    margin-bottom: 7%;
    background: white;
}

.box__two button:hover {
    background: black;
    color: white;
}

.box__two a {
    color: black;
}

.box__two a:hover {
    color: #ff8b3d;
}

.social__links a {
    margin: 10px;
    font-size: 20px;
}


/* Media query for small devices */
@media (max-width: 767px) {
    .blog__container {
        margin-right: 2rem;
        margin-left: 2rem;
    }

    .box__container {
        flex-direction: column;
        gap: 1rem;
        margin-top: 30%;
    }

    .box__one,
    .box__two {
        max-width: 100%;
    }
}

/* Media query for tablets */
@media (min-width: 768px) and (max-width: 1023px) {
    .box__two button {
        font-size: 14px; /* Adjust the button font size as needed */
        padding: 5px 10px; /* Adjust padding as needed */
    }

    
}