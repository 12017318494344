@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;600;800;900&family=Roboto:ital,wght@1,300&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}


.App {
  text-align: center;
}

/* .mainContainer{
  min-width:  350px;
} */
 

h1 {
  font-size: 20px;
  font-weight: Bold;
} 



