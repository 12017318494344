a {
    text-decoration: none;
}

ul {
    list-style: none;
}

#blog {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.blog-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.blog-heading span {
    color: blue;
}

.blog-heading h3 {
    font-size: 2.4rem;
    color: #222;
    font-weight: 600;
}

.blog-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    flex-wrap: wrap;
    gap: 2rem;
}

.blog-box {
    width: 350px;
    background-color: white;
    border: 1px solid #ececec;
}

.blog-img {
    width: 100%;
    height: auto;
}

.blog-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.blog-text {
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.blog-text span {
    color: blue;
    font-size: 0.9rem;
}

.blog-text .blog-title {
    font-size: 1.3rem;
    font-weight: 500;
    color: #222;
}

.blog-text .blog-title:hover {
    color: #ff8b3d;
    transition: all ease 0.3s;
}

.blog-text p {
    color: #9b9b9b;
    font-size: 0.9rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 20px 0px;
}

.blog-text a {
   color: #222;
}

.blog-text a:hover {
    color: #0496c7;
    transition: all ease 0.3s;
}

@media (max-width: 1250px) {
    .blog-box {
        width: 300px;
    }
}

@media (max-width: 1100px) {
    .blog-box {
        width: 70%;
    }
}

@media (max-width: 550px) {
    .blog-box {
        margin: 20px 10px;
        width: 100%;
    }

    #blog {
        padding: 20px;
    }
}