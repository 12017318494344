.aboutus {
    margin: 4rem 6rem;
    margin-top: 5rem;
}

.aboutus h1 {
    font-size: 2.5rem;
    font-size: 2.75rem;

}

.about__h {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 4.5rem;
}

.about__h__reverce {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 4.5rem;
}


.about__text {
    width: 45%;
    text-align: start;
    font-size: 1.1rem;
    line-height: 1.5rem;
    margin-bottom: -10%;
}

.about__text a {
    color: #ff8b3d;
    text-decoration: none;
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
}

.about__text h2 {
    padding-bottom: 1rem;
}

.about__img {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: -99;
}

.about__img img {
    width: 49%;
    height: 350px;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);

}

.about__img img:nth-child(2) {
    position: absolute;
    top: -10%;
    right: 0;
}

@media screen and (max-width: 850px) {
    .aboutus {
        margin: 4rem 2rem;
    }

    .about__h {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 2.5rem;
    }

    .about__text {
        width: 100%;
    }

    .about__img {
        width: 100%;
        margin: 1.8rem 0;
    }

    .about__img img {
        height: 250px;
    }


}